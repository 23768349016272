import { defineStore } from 'pinia'
import { OptionType } from '@/service/dict/types'
import { getDictOptionsApi } from '@/api/dict'
import { DictionaryValueVo } from '@/api/dict/types'
/**
 * 本地存储字典
 * */
export interface DictStore {
  typeCode: string
  dictData: Array<OptionType>
}

export interface DictTypes {
  dictStore: DictStore[]
}
export const useDictStore = defineStore('dict', {
  state: (): DictTypes => {
    return {
      dictStore: []
    }
  },
  actions: {
    /**
     * 清除所有字典
     * */
    clearAllDict(): void {
      this.dictStore = []
    },
    /**
     * 根据typeCode获取字典,先去缓存从获取，取不到 去接口获取，并将接口获取的数据 保存到缓存中
     * */
    getDictOptionsByTypeCode(typeCode: string): Promise<OptionType[]> {
      return new Promise(async (resolve) => {
        const _storeData = this.dictStore.find((item) => item.typeCode === typeCode)
        if (_storeData) {
          resolve(_storeData.dictData)
        } else {
          const response = await getDictOptionsApi(typeCode)
          const { data }: { data: DictionaryValueVo[] } = response
          const _storeDictData = data.map((ele: DictionaryValueVo) => {
            return {
              id: ele.id,
              parentId: ele.parentId,
              value: ele.codeValue,
              label: ele.codeName,
              codeDesc: ele.codeDesc
            }
          })
          const _dictStore: DictStore = {
            typeCode,
            dictData: _storeDictData
          }
          this.dictStore.push(_dictStore)
          resolve(_storeDictData)
        }
      })
    }
  },
  persist: true
})

import { queryIndividualInfoByIdApi } from '@/api/party'
import { PartyVo } from '@/api/party/types'
import { refreshLoginApi } from '@/api/login'
import { useUserStore } from '@/store/modules/user'
import { UserType } from '@/api/login/types'

export default class LoginService {
  /**
   * 获取当前登录用户的信息
   * @param id partyId
   * @return 当前登录用户的信息
   * */
  async queryCurrentUserInfo(id: string): Promise<any> {
    const _partyVo = (await queryIndividualInfoByIdApi(id)).data as PartyVo
    if ('LABOR' !== _partyVo.id) {
    }
  }

  /**
   * 刷新token
   * */
  async refreshToken() {
    const { getRefreshToken, setToken, setRefreshToken, setUserInfo, getUserInfo } = useUserStore()
    const username = getUserInfo?.username as string
    const password = getUserInfo?.password as string
    const res = await refreshLoginApi(getRefreshToken, username)
    const _userInfo: UserType = {
      operation_id: res.operator_id,
      party_id: res.party_id,
      party_name: res.party_name,
      party_role: res.party_role,
      username,
      password,
      role: 'admin',
      roleId: '1',
      roles: res.roles
    }
    setToken(res.access_token)
    setRefreshToken(res.refresh_token)
    setUserInfo(_userInfo)
  }
}

import request from '@/axios'
import { DictionaryValueVo, DictionaryVo, PageResult } from '@/api/dict/types'
const baseUrl = '/dcApi/v1/dc'
/**
 * 字典类型分页列表
 *
 * @param params
 */
export function getDictTypePageApi(params: any): Promise<IResponse<PageResult<DictionaryVo>>> {
  return request.get<any>({ url: `${baseUrl}/dictionary`, params })
}

/**
 * 字典类型表单数据
 *
 * @param id
 */
export function getDictTypeFormApi(id: string): Promise<IResponse<DictionaryVo>> {
  return request.get<any>({ url: `${baseUrl}/dictionary/${id}` })
}

/**
 * 新增字典类型
 *
 * @param data
 */
export function addDictTypeApi(data: any): Promise<IResponse> {
  const dictionaryDto = { ...data }
  return request.post<any>({ url: `${baseUrl}/dictionary`, data: { dictionaryDto } })
}

/**
 * 修改字典类型
 *
 * @param id
 * @param data
 */
export function updateDictTypeApi(id: string, data: any): Promise<IResponse> {
  const dictionaryDto = { ...data }
  return request.put<any>({ url: `${baseUrl}/dictionary/${id}`, data: { dictionaryDto } })
}

/**
 * 删除字典类型
 */
export function deleteDictTypesApi(ids: string[]): Promise<IResponse> {
  return request.delete<any>({ url: `${baseUrl}/dictionary`, data: { dictionaryIds: ids } })
}

/**
 * 获取字典类型的数据项
 *
 * @param dictionaryCode 字典类型编码
 */
export function getDictOptionsApi(dictionaryCode: string): Promise<IResponse<DictionaryValueVo[]>> {
  const params = { dictionaryCode }
  return request.get<any>({ url: `${baseUrl}/dictionary/dictionaryValue/all`, params })
}

/**
 * 字典分页列表
 */
export function getDictPageApi(params: any): Promise<IResponse<PageResult<DictionaryValueVo>>> {
  return request.get<any>({ url: `${baseUrl}/dictionary/dictionaryValue`, params })
}

/**
 * 获取字典表单数据
 *
 * @param id
 */
export function getDictFormDataApi(id: string): Promise<IResponse<DictionaryValueVo>> {
  return request.get<any>({ url: `${baseUrl}/dictionary/dictionaryValue/${id}` })
}

/**
 * 新增字典
 *
 * @param data
 */
export function addDictAPi(data: any): Promise<IResponse> {
  const { dictionaryId } = data
  const dictionaryValueDto = { ...data }
  return request.post<any>({
    url: `${baseUrl}/dictionary/${dictionaryId}/dictionaryValue`,
    data: { dictionaryValueDto }
  })
}

/**
 * 修改字典项
 *
 * @param id
 * @param data
 */
export function updateDictApi(id: string, data: any): Promise<IResponse> {
  const { dictionaryId } = data
  const dictionaryValueDto = { ...data }
  return request.put<any>({
    url: `${baseUrl}/dictionary/${dictionaryId}/dictionaryValue/${id}`,
    data: { dictionaryValueDto }
  })
}

/**
 * 删除字典
 * @param dictionaryId 字典类型ID
 * @param dictionaryValueIds 字典项ID，多个以英文逗号(,)分割
 */
export function deleteDictApi(
  dictionaryId: string,
  dictionaryValueIds: string[]
): Promise<IResponse> {
  return request.delete<any>({
    url: `${baseUrl}/dictionary/${dictionaryId}/dictionaryValue`,
    data: { dictionaryValueIds }
  })
}

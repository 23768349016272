import service from './service'
import { CONTENT_TYPE } from '@/constants'
import { useUserStoreWithOut } from '@/store/modules/user'
import LoginService from '@/service/LoginService'

const request = (option: AxiosConfig) => {
  const { url, method, params, data, headers, responseType } = option

  const userStore = useUserStoreWithOut()
  const withOutAuthorization = url?.startsWith('/loginApi')
  if (!withOutAuthorization && userStore.needRefreshToken()) {
    const loginService = new LoginService()
    loginService.refreshToken().finally(() => {
      console.log('刷新token完成')
    })
  }
  const _authorization =
    !withOutAuthorization && userStore.getToken
      ? {
          Authorization: userStore.getToken
        }
      : undefined
  return service.request({
    url: url,
    method,
    params,
    data: data,
    responseType: responseType,
    headers: {
      'Content-Type': CONTENT_TYPE,
      ..._authorization,
      ...headers
    }
  })
}

export default {
  get: <T = any>(option: AxiosConfig) => {
    return request({ method: 'get', ...option }) as Promise<IResponse<T>>
  },
  post: <T = any>(option: AxiosConfig) => {
    return request({ method: 'post', ...option }) as Promise<IResponse<T>>
  },
  delete: <T = any>(option: AxiosConfig) => {
    return request({ method: 'delete', ...option }) as Promise<IResponse<T>>
  },
  put: <T = any>(option: AxiosConfig) => {
    return request({ method: 'put', ...option }) as Promise<IResponse<T>>
  },
  getBlob: <T = any>(option: AxiosConfig) => {
    if (option) {
      option.responseType = 'blob'
    }
    return request({ method: 'get', ...option }) as Promise<IResponse<T>>
  },
  getPostBlob: <T = any>(option: AxiosConfig) => {
    if (option) {
      option.responseType = 'blob'
    }
    return request({ method: 'post', ...option }) as Promise<IResponse<T>>
  },
  cancelRequest: (url: string | string[]) => {
    return service.cancelRequest(url)
  },
  cancelAllRequest: () => {
    return service.cancelAllRequest()
  }
}

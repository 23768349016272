import request from '@/axios'
import type { UserType } from './types'
import qs from 'qs'

interface RoleParams {
  roleName: string
}

//http://172.25.18.7:9081/oauth/token?username=LIU_TEST3&password=123&grant_type=password&scope=pc-scope-1&client_id=pc-client-id&client_secret=pc-client-security
export const loginApi = (data: UserType): Promise<any> => {
  console.log('qs.stringify', qs.stringify(data))
  const userNameAndPassword = qs.stringify(data)
  return request.post({
    url: `/loginApi/oauth/token?${userNameAndPassword}&grant_type=password&scope=pc-scope-1&client_id=pc-client-id&client_secret=pc-client-security`
  })
}

export const refreshLoginApi = (refresh_token: string, username: string): Promise<any> => {
  return request.post({
    url: `/loginApi/oauth/token?refresh_token=${refresh_token}&grant_type=refresh_token&scope=pc-scope-1&client_id=pc-client-id&client_secret=pc-client-security&username=${username}`
  })
}

export const loginOutApi = (): Promise<IResponse> => {
  return new Promise((resolve) => {
    resolve({
      code: 200,
      data: null
    })
  })
}

export const getUserListApi = ({ params }: AxiosConfig) => {
  return request.get<{
    code: string
    data: {
      list: UserType[]
      total: number
    }
  }>({ url: '/mock/user/list', params })
}

export const getAdminRoleApi = (
  params: RoleParams
): Promise<IResponse<AppCustomRouteRecordRaw[]>> => {
  return request.get({ url: '/mock/role/list', params })
}

export const getTestRoleApi = (params: RoleParams): Promise<IResponse<string[]>> => {
  return request.get({ url: '/mock/role/list2', params })
}

import request from '@/axios'
import { Party } from '@/types/Party'
import { GroupDefinitionDto, PostLabelDto } from '@/api/party/types'
const baseUrl = '/partyApi/v1/party'
/**
 * 保存个人参与者
 * */
export const saveIndividualApi = (partyIndividual: Party) => {
  return request.post<any>({
    url: `${baseUrl}/individual`,
    data: { individualDto: partyIndividual }
  })
}

export const queryIndividualListApi = (params: any) => {
  return request.get<any>({ url: `${baseUrl}/individual`, params })
}
/**
 * 平铺
 * */
export const queryIndividualList4ConditionApi = (params: any) => {
  return request.get<any>({ url: `${baseUrl}/individual/condition`, params })
}

export const queryIndividualInfoByIdApi = (id: string) => {
  return request.get<any>({ url: `${baseUrl}/individual/${id}` })
}

export const updateIndividualInfoApi = (partyIndividual: Party) => {
  const id = partyIndividual.id
  return request.put<any>({
    url: `${baseUrl}/individual/${id}`,
    data: { individualDto: partyIndividual }
  })
}

/**
 * 保存组织参与者
 * */
export const saveOrganizationApi = (partyOrganization: Party) => {
  return request.post<any>({
    url: `${baseUrl}/organization`,
    data: {
      organizationDto: partyOrganization
    }
  })
}

/**
 * 添加个人参与者身份
 * */
export const addIndividualRoleApi = (data: any, id: string) => {
  return request.post<any>({
    url: `${baseUrl}/individual/${id}/role`,
    data
  })
}

/**
 * 移除个人参与者身份
 * */
export const deleteIndividualRoleApi = (data: any, id: string) => {
  return request.delete<any>({
    url: `${baseUrl}/individual/${id}/role`,
    data
  })
}

export const queryOrganizationListApi = (params: any) => {
  return request.get<any>({ url: `${baseUrl}/organization`, params })
}

export const queryOrganizationListDictApi = async (_params?: any) => {
  const res = (await queryOrganizationListApi({ page: 0, size: 1000000, ..._params })).data
  return res.content?.map((item: any) => {
    return {
      label: item.name,
      value: item.id
    }
  })
}

export const queryOrganizationInfoByIdApi = (id: string | undefined) => {
  return request.get<any>({ url: `${baseUrl}/organization/${id}` })
}

export const checkOrganizationInfoApi = (
  _data: any,
  unifiedSocialCreditCode: string,
  orgType: string,
  checkContactMedium: boolean,
  checkOrg: boolean,
  orgName: string
) => {
  //
  const data = {
    contactMediumDto: {
      contactType: 'TELEPHONE',
      number: _data.phone
    },
    partyIdentificationDto: {
      identificationCardNr: _data.identificationNumber,
      identificationType: 'ID_CARD'
    },
    checkContactMedium,
    checkOrg,
    orgType,
    unifiedSocialCreditCode,
    orgName
  }
  return request.post<any>({ url: `${baseUrl}/organization/check`, data })
}

export const updateOrganizationInfoApi = (partyOrganization: Party, orgId) => {
  return request.put<any>({
    url: `${baseUrl}/organization/${orgId}`,
    data: {
      organizationDto: partyOrganization
    }
  })
}

export const queryLaborInfoByCardNrApi = (cardNr: string) => {
  return request.get<any>({
    url: `${baseUrl}/individual/cardNr/${cardNr}/roleType/LABOR`
  })
}

export const queryPartyInfoByCardNrApi = (cardNr: string) => {
  const params = {
    identificationType: 'ID_CARD',
    identificationCardNr: cardNr
  }
  return request.get<any>({
    url: `${baseUrl}/individual/identification`,
    params
  })
}
/**
 * 查询子组织
 * */
export const querySubordinateByParentIdApi = (parentId: string) => {
  ///v1/party/organization/{parentId}/subordinate
  return request.get<any>({
    url: `${baseUrl}/organization/${parentId}/subordinate`
  })
}

/**
 * 查询全部子组织
 * */
export const querySubordinateAllByParentIdApi = (parentId: string) => {
  ///v1/party/organization/{parentId}/subordinate
  return request.get<any>({
    url: `${baseUrl}/organization/${parentId}/subordinate/all`
  })
}

/**
 * 上传劳务人员
 * */
export const uploadLaborFileApi = (file: File) => {
  return request.post<any>({
    url: `${baseUrl}/individual/verification`,
    data: { file: file },
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

/**
 * 上传照片
 * /v1/party/identificationFile/upload
 * */
export const uploadImageFileApi = (file: File) => {
  return request.post<any>({
    url: `${baseUrl}/identificationFile/upload`,
    data: { multipartFile: file },
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

/**
 * 通过参与人和角色类型查询参与人详情
 * */
export const queryPartyInfoByIdApi = (partyId: string, roleType: string) => {
  ///v1/party/individual/{partyId}/roleType/{roleType}
  return request.get<any>({
    url: `${baseUrl}/individual/${partyId}/roleType/${roleType}`
  })
}

/**
 * 给项目添加参与人
 * */
export const addProjectPartyApi = (projectId: string, partyRoleDtos: any) => {
  ///v1/party/individual/project/{projectId}
  const data = {
    projectDto: {
      partyRoleDtos,
      projectId
    }
  }
  return request.post<any>({
    url: `${baseUrl}/individual/project/${projectId}`,
    data
  })
}

/**
 * 给项目移除参与人
 * */
export const removeProjectPartyApi = (projectId: string, partyRoleDtos: any) => {
  ///v1/party/individual/project/{projectId}
  const data = {
    projectDto: {
      partyRoleDtos,
      projectId
    }
  }
  return request.delete<any>({
    url: `${baseUrl}/individual/project/${projectId}`,
    data
  })
}

/**
 * 根据角色id查询参与人信息
 * */
export const queryPartyInfoByRoleIdApi = (partyRoleId: string) => {
  ///v1/party/individual/project/{projectId}
  return request.get<any>({
    url: `${baseUrl}/individual/role/${partyRoleId}`
  })
}
/**
 * 添加分包商超级管理员
 * */
export const updateOrganizationPartyApi = (
  orgId: string,
  partyId: string,
  partyRoleType: string
) => {
  const updateOrganizationPartyRolePayload = {
    organizationPartyRoleDto: {
      orgId,
      partyId,
      partyRoleType
    }
  }
  return request.put<any>({
    url: `${baseUrl}/organization/${orgId}/role`,
    data: updateOrganizationPartyRolePayload
  })
}

/**
 * 删除自然人
 * */
export const removePartyByPartyIdApi = (partyId: string) => {
  const data = {}
  return request.delete<any>({ url: `${baseUrl}/individual/${partyId}`, data })
}

/**
 * 删除分包商
 * */
export const removeSubordinateByPartyIdApi = (partyId: string) => {
  const data = {}
  return request.delete<any>({ url: `${baseUrl}/organization/${partyId}/subordinate`, data })
}

/**
 * 导出分包商
 * */
export const exportOrganizationApi = (params: any) => {
  return request.getBlob<any>({ url: `${baseUrl}/organization/export`, params })
}

/**
 * 分组管理
 * */

/**
 * 创建分组
 * */
export const createGroupDefinitionApi = (groupDefinitionDto: GroupDefinitionDto) => {
  return request.post<any>({ url: `${baseUrl}/groupDefinition`, data: { groupDefinitionDto } })
}

/**
 * 查询分组详情
 * */
export const queryGroupDefinitionInfoByIdApi = (id: string) => {
  return request.get<any>({ url: `${baseUrl}/groupDefinition/${id}` })
}

/**
 * 查询分组成员列表
 * */
export const queryGroupDefinitionIndividualPageApi = (id: string, params: any) => {
  params['groupId'] = id
  return request.get<any>({ url: `${baseUrl}/individual/condition`, params })
}
/**
 * 修改分组
 * */
export const updateGroupDefinitionApi = (id: string, groupDefinitionDto: GroupDefinitionDto) => {
  return request.put<any>({ url: `${baseUrl}/groupDefinition/${id}`, data: { groupDefinitionDto } })
}
/**
 * 删除分组
 * */
export const deleteGroupDefinitionByIdApi = (id: string) => {
  return request.delete<any>({ url: `${baseUrl}/groupDefinition/${id}` })
}

/**
 * 分页查询分组
 * */
export const queryGroupDefinitionConditionPageApi = (params: any) => {
  return request.get<any>({ url: `${baseUrl}/groupDefinition/condition`, params })
}

/**
 * 添加分组成员
 * */
export const addIndividualGroupDefinitionApi = (
  id: string,
  groupDefinitionDto: GroupDefinitionDto
) => {
  return request.post<any>({
    url: `${baseUrl}/groupDefinition/${id}/individual`,
    data: groupDefinitionDto
  })
}
/**
 * 移除分组成员
 * */
export const removeIndividualGroupDefinitionApi = (
  id: string,
  groupDefinitionDto: GroupDefinitionDto
) => {
  return request.delete<any>({
    url: `${baseUrl}/groupDefinition/${id}/individual`,
    data: groupDefinitionDto
  })
}

/**
 * 查询分组成员
 * */
export const queryIndividualGroupDefinitionApi = (params: any) => {
  return request.get<any>({
    url: `${baseUrl}/groupDefinition/individual/condition`,
    params
  })
}

/**
 * 添加岗位标签
 * */
export const addIndividualPostLabelApi = (
  partyId: string,
  partyRoleId: string,
  postLabelDtos: PostLabelDto[]
) => {
  return request.post<any>({
    url: `${baseUrl}/individual/${partyId}/${partyRoleId}/postLabel`,
    data: { postLabelDtos }
  })
}

/**
 * 删除岗位标签
 * */
export const removeIndividualPostLabelApi = (
  partyId: string,
  partyRoleId: string,
  postLabelId: string,
  postLabelDto?: PostLabelDto
) => {
  console.log(postLabelDto)
  return request.delete<any>({
    url: `${baseUrl}/individual/${partyId}/${partyRoleId}/postLabel/${postLabelId}`,
    data: { postLabelDto: { labelCode: '', labelName: '', partyRoleId: '' } }
  })
}

/**
 * 批量删除岗位标签
 * */
export const removeIndividualPostLabelBatchApi = async (
  partyId: string,
  partyRoleId: string,
  postLabelIds: string[],
  postLabelDto?: PostLabelDto
) => {
  const postLabelIdsLen = postLabelIds.length
  for (let i = 0; i < postLabelIdsLen; i++) {
    await removeIndividualPostLabelApi(partyId, partyRoleId, postLabelIds[i], postLabelDto)
  }
}

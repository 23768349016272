import { defineStore } from 'pinia'
import {
  AuthSealVo,
  OrganizationAuthVo,
  OrganizationInfoVo,
  SealVo,
  TransactorInfoVo
} from '@/api/seal/types'
import { queryOrgAuthApi } from '@/api/seal'
import { useUserStore } from '@/store/modules/user'
import { ShopOrderQueryDto } from '@/api/shop/types'
export const useSealStore = defineStore('seal', {
  state: (): OrganizationAuthVo => {
    return {
      name: undefined,
      organizationInfo: undefined,
      transactorInfo: undefined,
      seals: undefined,
      authSeals: undefined,
      businessKey: undefined,
      state: undefined
    }
  },
  getters: {
    getAuthState(): string | undefined {
      return this.state
    },
    getTransactorInfo(): TransactorInfoVo | undefined {
      return this.transactorInfo
    },
    getBusinessKey(): string | undefined {
      return this.businessKey
    },
    getShopOrderParams(): ShopOrderQueryDto {
      return {
        orgId: this.businessKey,
        transactorPsnId: this.transactorInfo?.psnId
      }
    },
    getSeals(): Array<SealVo> | undefined {
      return this.seals
    },
    getAuthSeals(): Array<AuthSealVo> | undefined {
      return this.authSeals
    }
  },
  actions: {
    /**
     * 清除缓存
     * */
    clearSeal() {
      this.name = undefined
      this.organizationInfo = undefined
      this.transactorInfo = undefined
      this.seals = undefined
      this.authSeals = undefined
      this.businessKey = undefined
    },
    /**
     * 查询当前party 的授权信息1
     * */
    queryPartySealAuth(): Promise<any> {
      const partyId: string = useUserStore().getPartyInfo.partyRoleVos[0].id as string
      return new Promise((resolve) => {
        queryOrgAuthApi(partyId).then((res) => {
          if (res.data) {
            this.name = res.data.name
            this.organizationInfo = res.data.organizationInfo as OrganizationInfoVo
            this.transactorInfo = res.data.transactor as TransactorInfoVo
            this.seals = res.data.seals as SealVo[]
            this.authSeals = res.data.authSeals as AuthSealVo[]
            this.businessKey = res.data.businessKey
            this.state = res.data.state
          } else {
            this.clearSeal()
          }
          resolve({})
        })
      })
    }
  },
  persist: true
})

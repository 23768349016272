import request from '@/axios'
import { OrganizationAuthType } from '@/types/SealTypes'
import { ShopOrderQueryDto } from '@/api/shop/types'
const baseUrl = '/sealApi/v1/org/auth'

// const _partyId = 'party-id-1'
export const creatSealApi = (partyId: string): Promise<any> => {
  return request.post({
    url: `/sealApi/v1/org/auth/${partyId}/seal`,
    data: { partyId: partyId }
  })
}
/**
 *授权印章
 * */
export const platformAuthSealApi = (partyId: string, sealId: string) => {
  return request.post({
    url: `/sealApi/v1/platform/auth/org/seal`,
    data: { partyId: partyId, sealId }
  })
}

/**
 * 根据人员获取授权信息
 * */

export const queryOrgAuthApi = (partyId: string) => {
  // todo 这里用的是party-id-1
  return request.get({
    url: `${baseUrl}?partyId=${partyId}`
  })
}

/**
 *授权
 * */
export const orgAuthApi = (organizationAuth: OrganizationAuthType) => {
  return request.post({
    url: `${baseUrl}`,
    data: { organizationAuth }
  })
}

/**
 *清除授权
 * */
export const clearOrgAuthApi = (orgId: string) => {
  return request.put({
    url: `${baseUrl}/unAuth`,
    data: { orgId }
  })
}
/**
 * 获取授权页面接口
 * */
export const queryAuthPageApi = (params: ShopOrderQueryDto): Promise<IResponse> => {
  return request.get({
    url: `/sealApi/v1/platform/auth/org/console`,
    params
  })
}
